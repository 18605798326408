import useFieldRowController from "./FieldRowController";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Paper,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import "./FieldRow.css";
const FieldRow = () => {
  const {
    setContainerFiltered,
    setScacFiltered,
    handleClearFilters,
    handleTableSelected,
    tableSelected,
    locations,
    handleSetLocationSelected,
    locationSelected,
  } = useFieldRowController();

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid xs={12} md={6} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Location</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={locationSelected}
                label="Location"
                onChange={(e) => handleSetLocationSelected(e)}
              >
                {locations.map((option, index) => (
                  <MenuItem key={index} value={option.text}>
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} md={6} lg={2}>
            <TextField
              fullWidth
              label="SCAC"
              id="scacTextfield"
              variant="outlined"
              onChange={(e) => setScacFiltered(e.target.value)}
            />
          </Grid>
          <Grid xs={12} md={6} lg={2}>
            <TextField
              fullWidth
              label="Cont/Trl/chs"
              id="contTextField"
              variant="outlined"
              onChange={(e) => setContainerFiltered(e.target.value)}
            />
          </Grid>
          <Grid xs={12} md={6} lg={1} display={"flex"}>
            <Button
              variant="outlined"
              minHeight={"100%"}
              onClick={(e) => handleClearFilters()}
            >
              <FilterAltOffIcon />
            </Button>
          </Grid>
          <Grid md={"none"} lg={2} padding={"0"} />
          <Grid xs={12} md={6} lg={2} />
          {/* <Grid xs={12} md={6} lg={2}>
                    <Button variant="outlined" fullWidth id={tableSelected ? "tableSelected" : "tableNotSelected"} onClick={(e) => handleTableSelected(true)} >Pending Flip</Button>
                </Grid> */}
          {/* <Grid xs={12} md={6} lg={2}>
                    <Button variant="outlined" id={tableSelected ? "tableNotSelected" : "tableSelected"} fullWidth onClick={(e) => handleTableSelected(false)} >Flip History</Button>
                </Grid> */}
        </Grid>
      </Box>
    </>
  );
};
export default FieldRow;
