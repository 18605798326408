import * as React from "react";
// store.js
export const StoreContext = React.createContext(null);

export const StoreProvider = ({ children }) => {
  const [containerFiltered, setContainerFiltered] = React.useState("");
  const [scacFiltered, setScacFiltered] = React.useState("");
  const [tableSelected, setTableSelected] = React.useState(true);
  const [locationSelected, setLocationSelected] = React.useState("");

  return (
    <StoreContext.Provider
      value={{
        containerFiltered,
        setContainerFiltered,
        scacFiltered,
        setScacFiltered,
        tableSelected,
        setTableSelected,
        locationSelected,
        setLocationSelected,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
