import { axiosInstance } from "./AppInsightsService";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
export const getLocationsByOwner = async (ScacCode) => {
  try {
    const config = {
      headers: headers,
      method: "get",
      url: process.env.REACt_APP_GET_YARD_LOCATIONS_BY_OWNER,
      params: {
        ScacCode: ScacCode,
      },
      nameEvent: "FlipModule-getLocations",
    };
    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error("Error al obtener las ubicaciones:", error);
    throw error;
  }
};

export const getPendingFlip = async () => {
  const config = {
    headers: headers,
    method: "get",
    url: process.env.REACT_APP_GET_PENDING_FLIPS,
    nameEvent: "FlipModule-getPendingFlip",
  };
  const response = axiosInstance(config);
  return response;
};
