import * as React from "react";
import { StoreContext } from "../../store/storeContext";
import { getLocationsByOwner } from "../../services/services";
import Cookies from "universal-cookie";
const useFieldRowController = () => {
  const {
    setContainerFiltered,
    setScacFiltered,
    tableSelected,
    setTableSelected,
    locationSelected,
    setLocationSelected,
  } = React.useContext(StoreContext);
  const cookies = new Cookies();
  //Consants
  const [locations, setLocations] = React.useState([]);

  const fetchLocations = async () => {
    getLocationsByOwner(cookies.get("sessionCosmos").scac_code).then(
      (response) => {
        let locations = [];
        locations = [{ value: "All", text: "All" }];
        response.Data.map((location) => {
          locations.push({
            value: location.YardId.CodeName,
            text: location.YardId.CodeName,
          });
        });

        setLocationSelected(locations[1].text);
        setLocations(locations);
      }
    );
  };

  //Functions
  const handleClearFilters = () => {
    const scac = document.getElementById("scacTextfield");
    const cont = document.getElementById("contTextField");
    scac.value = "";
    cont.value = "";
    setContainerFiltered("");
    setScacFiltered("");
    setLocationSelected("All");
  };

  const handleTableSelected = (state) => {
    handleClearFilters();
    setTimeout(() => {
      setTableSelected(state);
    }, 100);
  };

  const handleSetLocationSelected = (e) => {
    setLocationSelected(e.target.value);
  };
  //UseEffect
  React.useEffect(() => {
    fetchLocations();
  }, []);
  return {
    setContainerFiltered,
    setScacFiltered,
    handleClearFilters,
    handleTableSelected,
    tableSelected,
    locations,
    locationSelected,
    handleSetLocationSelected,
  };
};
export default useFieldRowController;
