// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tableSelected {
    background-color: #23224F;
    color: white;

}

#tableNotSelected {
    color: #23224F;
    border-color:#23224F;

}`, "",{"version":3,"sources":["webpack://./src/components/FieldRow/FieldRow.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;;AAEhB;;AAEA;IACI,cAAc;IACd,oBAAoB;;AAExB","sourcesContent":["#tableSelected {\n    background-color: #23224F;\n    color: white;\n\n}\n\n#tableNotSelected {\n    color: #23224F;\n    border-color:#23224F;\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
